<template>
  <div class="contents">
    <div class="vr-scene">
      <a-scene id="scene">
        <a-assets id="assets-area" />
        <RoomScene
          ref="room"
          :image-route-path="imageRoutePath"
          :glb-route-path="routePath + 'glb/'"
          :json-data="jsonData"
          @setGlbData="setGlbData"
          @loadFlag="loadFlag=true"
          @setConfirmWindow="setConfirmWindow"
        />
      </a-scene>
    </div>
    <LoadingView v-if="!loadFlag" />
    <ConfirmWindow
      v-if="confirmWindowFlag"
      :target-url="targetUrl"
      @close-window="confirmWindowFlag=false"
    />
  </div>
</template>

<script>
import utilsMixin from '../mixins/utils'
import LoadingView from '../components/LoadingView'
import ConfirmWindow from '../components/ConfirmWindow'
import RoomScene from '../components/RoomScene'
import settingJson from '../assets/setting-original.json';

export default {
  name: 'OriginalView',
  components: {
    RoomScene,
    LoadingView,
    ConfirmWindow
  },
  mixins: [utilsMixin],
  props: {},
  data() {
    return {
      title: 'ページタイトル',                       // ページタイトル
      description: 'ページ説明',                    // ページ説明
      loadFlag: false,                            // ロード画面用フラグ
      confirmWindowFlag:false,                    // 遷移先確認画面表示フラグ
      targetUrl: '',                              // 遷移先URL
      jsonData: settingJson,                      // 初期設定の情報
      imageRoutePath: '/assets/original/images/', // 画像ルートパス
      routePath: '/assets/original/',             // 顔データルートパス
      glbData: {}                                 // 子Vueで取得したGLBデータ
    }
  },
  watch: {
    /*******************************************
     * Loadフラグが更新されたらアニメーションを設定
     ******************************************/
    loadFlag(newBool) {
      if(newBool) {
        for (let property in this.glbData.animation) {
          // アニメーションを設定
          this.setAnimation(property);
        }
      }
    }
  },
  mounted() {
    // タイトルと説明、OGPを設定
    this.changePageContent();
  },
  methods: {
    /*******************************************
     * 子から受け取った建物データを設定
     ******************************************/
    setGlbData(glbData) {
      this.glbData = glbData;
    },
    /*******************************************
     * 画面遷移のConfirm画面呼び出し
     ******************************************/
    setConfirmWindow(target) {
      this.targetUrl =  this.jsonData.linkData[target];
      this.confirmWindowFlag = true;
    },
    /*******************************************
     * パーツごとにアニメーションを設定する
     ******************************************/
    setAnimation(animationName) {
      let animationElement = document.getElementById(animationName);
      console.log(animationElement.id)
    }
  }
}
</script>

<style scoped>
.contents {
  height: 100vh;
}
.vr-scene {
  height: 100%;
}

</style>
